import UserMeta from "./UserMeta";
import Config from "./Config";
import Deus from "./Deus";
import Api from "./Api";
import Cdp from "./Cdp";
import HubSpotConnector from "./HubSpotConnector";

const _ = require("lodash")

class SurveyNPS {

    #questionable = false;

    #key;
    #NPSGroup;

    constructor() {

        if (!SurveyNPS._instance) {
            SurveyNPS._instance = this;


        } else {
            console.log("class already inizialized")
        }

        this.init();

        //console.log(Config._instance)
        return SurveyNPS._instance;


    }

    /**
     * Determines if the current state is questionable.
     *
     * @returns {boolean} Returns true if the state is questionable, otherwise false.
     */
    isQuestionable() {
        /*
        // disabilito NPS se può fare PTR
        if(UserMeta.getInstance().userProduct && UserMeta.getInstance().userProduct.subscription){
            const startSubscription = new Date(UserMeta.getInstance().userProduct.subscription._schedule_start);
            if(startSubscription > new Date('2023-04-01') && startSubscription < new Date('2023-11-31')) return false;
        }*/

        const moment = require('moment');


        const lastNPS = UserMeta.getInstance().userNPS;
        const hasNPS = Object.keys(lastNPS).length > 0;
        const isTrial = UserMeta.isTrial;
        const today = moment();

        // check se su local storage ha dismesso la modale e se si da quanto tempo
        const deniedNPS = localStorage.getItem(process.env.NPS_LOCAL_KEY);
        let canShow = true;

        if (deniedNPS) {
            let nextNPSDate;
            if (!isTrial) {
                nextNPSDate = moment(deniedNPS).add(1, 'week');
            } else {
                nextNPSDate = moment(deniedNPS).add(1, 'day');
            }
            if (nextNPSDate.isBefore(today)) {
                localStorage.removeItem(process.env.NPS_LOCAL_KEY)
                canShow = true;
            } else {
                canShow = false;
            }
        }

        if (!canShow) return false;

        // l'ha mai fatto
        if (hasNPS) {
            // se ha già un nps ed è un trial allora non deve più farlo
            if (isTrial) return false;

            // guardo quanto tempo è passato dall'ultima raccolta dell'nps
            const nextNPSDate = moment(lastNPS.data).add(2, 'month');

            // se è il giorno dell'nps o dopo allora true
            if (today.isAfter(nextNPSDate) || nextNPSDate.isSame(today, 'day')) {
                return true;
            }
            return false;
        } else {
            const subscriptionInfo = Config.options.u.products[0].subscription
            // NOTA, se è TRIAL non avrà schedule_next_payment ma schedule_end e _schedule_end_days
            if (isTrial) {
                // se è un trial guardo se sono passati 3 giorni dall'attivazione
                return subscriptionInfo._schedule_start_days > 1;
            } else {
                // se è un customer guardo se è passato almeno un mese dall'attivazione
                return subscriptionInfo._schedule_start_days > 30;
            }
        }

    }

    createInfo(vote) {


        if (vote <= 6) {
            this.#NPSGroup = "DETRACTOR"
        } else if (vote > 6 && vote < 9) {
            this.#NPSGroup = "NEUTRAL"
        } else if (vote >= 9) {
            this.#NPSGroup = "PROMOTER"
        }


        return new Promise((resolve, reject) => {
            Api.postV2({
                action: "add_nps_vote",
                vote: vote,
                uid: UserMeta.userMeta.uid,
                "trial": UserMeta.isTrial,
                "plan": UserMeta.getInstance().userProduct.product.name.toUpperCase(),
            }).then((response) => {
                resolve(response);

                this.#key = response.data.key;
                Cdp.trackEvent("NPS Score", {
                    source: 'app',
                    vote: vote,
                    objectKey: this.#key,
                    NpsGroup: this.#NPSGroup
                });

                Cdp.identifyWithTrait({"NpsScore": vote, "NpsGroup": this.#NPSGroup}).then((response)=>{
                    HubSpotConnector.sendForm('NpsSurveyScore', {
                        "submittedAt": new Date().getTime(),
                        "fields": [
                            {
                                name: "email",
                                value: UserMeta.userMeta.email
                            },
                            {
                                name: "nps_score",
                                value: this.#NPSGroup.charAt(0) + this.#NPSGroup.slice(1).toLowerCase()
                            }
                        ],
                        "context": {
                            //"hutk": document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                            "pageUri": window.location.href,
                            "pageName": document.title
                        },
                    })
                })

            })
        });

    }

    /*
    * nps_score : "Detractor"
    * nps_score : "Neutral"
    * nps_score : "Promoter"
    * subject : "..."
    * content : "..."
    * hs_ticket_category : "PRODUCT_ISSUE"
    * hs_ticket_category : "BILLING_ISSUE"
    * hs_ticket_category : "FEATURE_REQUEST"
    * hs_ticket_category : "GENERAL_INQUIRY"
    */

    updateInfo(msg) {
        const key = this.#key
        return Api.postV2({
            action: "update_nps_vote",
            uid: UserMeta.userMeta.uid,
            key: key,
            msg: msg
        }, true).then(() => {
            if (msg && msg.length > 0) {
                Cdp.trackEvent("NPS Feedback", {msg: msg, objectKey: key}).then(() => {
                    HubSpotConnector.sendForm('NpsSurveyFeedback', {
                        "submittedAt": new Date().getTime(),
                        "fields": [
                            {
                                name: "email",
                                value: UserMeta.userMeta.email
                            },
                            {
                                name: "TICKET.hs_ticket_category",
                                value: "PRODUCT_ISSUE"
                            },
                            {
                                name: "nps_score",
                                value: this.#NPSGroup.charAt(0) + this.#NPSGroup.slice(1).toLowerCase()
                            },
                            {
                                name: "TICKET.subject",
                                value: "NPS Feedback"
                            },
                            {
                                name: "TICKET.content",
                                value: msg
                            }
                        ],
                        "context": {
                            //"hutk": document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
                            "pageUri": window.location.href,
                            "pageName": document.title
                        },
                    })
                })
            }
        });
    }

    init() {
        if (this.isQuestionable()) {
            setTimeout(() => {
                Cdp.trackEvent("NPS showed", {})
                // questo lo setto per non far comparire la modale a ogni sessione...
                // se compare poi riappare dopo 1 giorno per il trial e dopo una
                // settimana per i clienti
                localStorage.setItem(process.env.NPS_LOCAL_KEY, new Date().toJSON())
                Deus.$emit('openModal', {"key": "survey:nps"});
            }, process.env.NPS_DELAY)
        }
    }


    static getInstance() {
        return this._instance;
    }


}

export default SurveyNPS